:root {
  --background-color: #282a34;
  --log-background-color: rgba(40, 42, 52, 0.82);
  --keyword-color: #00d5a3;
  --identifier-highlight-color: #fff183;
  --identifier-hover-background-color: #6E6D6D;
  --text-color-dim: #878787;
  --text-color: #d2d2d2;
  --text-color-bright: #fff;
  --numeric-color: yellow;
  --cursor-color: #d2d2d2;
  --delete-color: #f77272;
  --delete-color-highlight: #fa4545;
}

@keyframes cursor-blink {
  from {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "Roboto", serif;
  font-weight: 300;
}

.row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

#root {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
#root #editor {
  width: 40%;
  height: 100%;
}
#root #editor .editor {
  position: relative;
  width: 100%;
  height: 100%;
}
#root #editor .editor .reset {
  position: absolute;
  right: 25px;
  top: 10px;
  transition: color 100ms linear;
  color: var(--cursor-color);
  cursor: pointer;
}
#root #editor .editor .reset:hover {
  color: var(--identifier-highlight-color);
}
#root #editor .editor .reset.enabled {
  color: var(--identifier-highlight-color);
}
#root #editor .editor .src {
  font-family: "Courier New", Courier, monospace !important;
  width: 100%;
  height: 100%;
  white-space: pre;
  overflow: auto;
}
#root #editor .editor .src span {
  position: relative;
  min-width: 3px;
}
#root #editor .editor .src .cursor:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
  background-color: var(--cursor-color);
  animation: cursor-blink 1.5s infinite;
}
#root #editor .editor .src .line {
  margin-left: 10px;
}
#root #editor .editor .src .line:first-of-type {
  margin-top: 10px;
}
#root #editor .editor .src .keyword {
  color: var(--keyword-color);
}
#root #editor .editor .src .unknown {
  color: #777;
}
#root #editor .editor .src .identifier:hover {
  background-color: var(--identifier-hover-background-color);
  cursor: pointer;
}
#root #editor .editor .src .identifier.hover {
  color: var(--identifier-highlight-color);
}
#root #editor .editor .src .numeric {
  color: var(--numeric-color);
}
#root #renderer {
  flex: 1;
  height: 100%;
}
#root #renderer .arrow {
  transition: color 100ms linear;
  color: var(--cursor-color);
  cursor: pointer;
}
#root #renderer .arrow:hover {
  color: var(--identifier-highlight-color);
}
#root #renderer .arrow.enabled {
  color: var(--identifier-highlight-color);
}
#root #renderer .machine-ui {
  border-left: 1px solid var(--text-color);
  display: flex;
  height: 100%;
  width: 100%;
}
#root #renderer .machine-ui .system-log {
  position: absolute;
  bottom: -100%;
  width: calc(100% - 20px);
  padding: 10px;
  box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.3);
  background: var(--log-background-color);
  z-index: 10;
  transition: bottom 500ms cubic-bezier(1, 0, 0, 1);
}
#root #renderer .machine-ui .system-log.has-errors {
  bottom: 0;
}
#root #renderer .machine-ui .system-log .clear-errors {
  position: absolute;
  top: 4px;
  right: 5px;
  transition: color 100ms linear;
  color: var(--cursor-color);
  cursor: pointer;
}
#root #renderer .machine-ui .system-log .clear-errors:hover {
  color: var(--delete-color-highlight);
}
#root #renderer .machine-ui .system-log .clear-errors.enabled {
  color: var(--delete-color-highlight);
}
#root #renderer .machine-ui .system-log .messages {
  overflow: auto;
  max-height: 150px;
}
#root #renderer .machine-ui .system-log .messages .message {
  padding: 5px;
}
#root #renderer .machine-ui .system-log .messages .message span {
  margin-left: 10px;
}
#root #renderer .machine-ui .system-log .messages .message .obj {
  font-family: "Courier New", Courier, monospace !important;
}
#root #renderer .machine-ui .system-log .messages .error {
  background: rgba(79, 7, 17, 0.46);
}
#root #renderer .machine-ui .timeline {
  height: 60px;
  padding: 0 10px;
  display: flex;
}
#root #renderer .machine-ui .timeline .machine-states {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
#root #renderer .machine-ui .timeline .controls {
  width: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#root #renderer .machine-ui .timeline .controls > * {
  cursor: pointer;
}
#root #renderer .machine-ui .timeline .state-entry {
  flex: 1;
  display: flex;
  justify-content: center;
}
#root #renderer .machine-ui .timeline .state-entry .inner-marker {
  width: 2px;
  height: 100%;
  background-color: var(--text-color-bright);
}
#root #renderer .machine-ui .timeline .state-entry.has-hovered .inner-marker {
  background-color: var(--identifier-highlight-color);
}
#root #renderer .machine-ui .timeline .state-entry.disabled .inner-marker {
  background-color: #525252;
}
#root #renderer .machine-ui .timeline .state-entry.active .inner-marker {
  background-color: var(--keyword-color);
}
#root #renderer .machine-ui .stage {
  overflow: hidden;
  flex: 1;
  fill: var(--text-color);
  display: flex;
}
#root #renderer .machine-ui .stage > p {
  flex: 1;
}
#root #renderer .machine-ui .stage .range-select {
  padding: 5px 20px;
}
#root #renderer .machine-ui .globals {
  width: 250px;
  height: 100%;
  border-left: 1px solid var(--text-color);
  cursor: default;
}
#root #renderer .machine-ui .globals .global {
  padding: 10px;
  min-height: 30px;
  border-bottom: 1px solid var(--text-color);
  color: var(--text-color-dim);
}
#root #renderer .machine-ui .globals .global input {
  width: 50px;
}
#root #renderer .machine-ui .globals .global.in-top {
  color: var(--text-color-bright);
}
#root #renderer .machine-ui .globals .global .color {
  position: relative;
  width: 40px;
  flex-shrink: 0;
  align-items: center;
  flex: initial;
}
#root #renderer .machine-ui .globals .global .color .swatch {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}
#root #renderer .machine-ui .globals .global .color .picker {
  position: absolute;
  top: 55px;
  left: -65px;
  z-index: 1;
}
#root #renderer .machine-ui .globals .global .params {
  display: flex;
  flex-direction: column;
}
#root #renderer .machine-ui .globals .global .params input {
  flex: 1;
}
#root #renderer .machine-ui .globals .global .params span {
  flex: 1;
}
#root #renderer .machine-ui .globals .global.hover .id-name {
  color: var(--identifier-highlight-color);
}
#root #renderer .machine-ui .globals .global .layer-controls {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#root #renderer .machine-ui .globals .global .layer-controls .delete {
  color: var(--delete-color);
  transition: color 100ms linear;
  color: var(--cursor-color);
  cursor: pointer;
}
#root #renderer .machine-ui .globals .global .layer-controls .delete:hover {
  color: var(--delete-color-highlight);
}
#root #renderer .machine-ui .globals .global .layer-controls .delete.enabled {
  color: var(--delete-color-highlight);
}
/*# sourceMappingURL=index.af312c6e.css.map */
