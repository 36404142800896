//@import "node_modules/roboto-fontface/css/roboto/sass/roboto-fontface";

//@media(prefers-color-scheme: dark) {
    :root {
        --background-color: #282a34;
        --log-background-color: rgba(40, 42, 52, 0.82);
        --keyword-color: #00d5a3;
        --identifier-highlight-color: #fff183;
        --identifier-hover-background-color: #6E6D6D;
        --text-color-dim: #878787;
        --text-color: #d2d2d2;
        --text-color-bright: #fff;
        --numeric-color: yellow;
        --cursor-color: #d2d2d2;
        --delete-color: #f77272;
        --delete-color-highlight: #fa4545;
    }
//}

@keyframes cursor-blink {
    from {
        opacity: 0;
    }

    // ramp
    10% {
        opacity: 1;
    }

    // hold
    40% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }

    // ramp
    60% {
        opacity: 0;
    }

    to {
        opacity: 0;
    }
}

@mixin button($hover-color: var(--identifier-highlight-color)) {
    transition: color 100ms linear;

    color: var(--cursor-color);
    cursor: pointer;

    &:hover {
        color: $hover-color;
    }

    &.enabled {
        color: $hover-color;
    }
}

@mixin code-font {
    font-family: 'Courier New', Courier, monospace !important;
}

body {
    margin: 0;
    padding: 0;

    background-color: var(--background-color);
    color: var(--text-color);

    font-family: 'Roboto', serif;
    font-weight: 300;
}

.row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // border: 3px solid red;
}

.col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    // Catch positioned elements;
    position: relative;
}


#root {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    overflow: hidden;

    #editor {
        width: 40%;
        height: 100%;

        .editor {
            position: relative;
            width: 100%;
            height: 100%;

            .reset {
                position: absolute;
                right: 25px;
                top: 10px;

                @include button;
            }

            .src {
                @include code-font;

                width: 100%;
                height: 100%;
                white-space: pre;
                overflow: auto;

                span {
                    position: relative;
                    min-width: 3px;
                }

                .cursor {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 3px;

                        background-color: var(--cursor-color);
                        animation: cursor-blink 1.5s infinite;
                    }
                }

                .line {
                    &:first-of-type {
                        margin-top: 10px;
                    }

                    margin-left: 10px;
                }


                .keyword {
                    color: var(--keyword-color);
                }

                .unknown {
                    color: #777;
                }

                .identifier:hover {
                    background-color: var(--identifier-hover-background-color);
                    cursor: pointer;
                }

                .identifier.hover {
                    color: var(--identifier-highlight-color);
                }

                .numeric {
                    color: var(--numeric-color);
                }
            }
        }
    }

    #renderer {
        flex: 1;
        height: 100%;

        .arrow {
            @include button;
        }

        .machine-ui {
            border-left: 1px solid var(--text-color);
            display: flex;
            height: 100%;
            width: 100%;

            .system-log {
                position: absolute;
                bottom: -100%;
                width: calc(100% - 20px); // I forget how to do this correctly...
                padding: 10px;

                box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.3);
                background: var(--log-background-color);
                z-index: 10;

                transition: bottom 500ms cubic-bezier(1, 0, 0, 1);

                &.has-errors {
                    bottom: 0;
                }

                .clear-errors {
                    position: absolute;
                    top: 4px;
                    right: 5px;

                    @include button(var(--delete-color-highlight));
                }

                .messages {
                    overflow: auto;
                    max-height: 150px;

                    .message {
                        padding: 5px;
                        span {
                            margin-left: 10px;
                        }

                        .obj {
                            @include code-font;
                        }
                    }
                    //.info {
                    //    background: rgba(20, 79, 7, 0.46);
                    //}

                    .error {
                        background: rgba(79, 7, 17, 0.46);
                    }
                }

            }

            .timeline {
                height: 60px;
                padding: 0 10px;
                display: flex;

                .machine-states {
                    flex: 1;

                    display: flex;
                    justify-content: space-between;
                }

                .controls {
                    width: 35px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    > * {
                        cursor: pointer;
                    }
                }

                .state-entry {
                    flex: 1;
                    display: flex;
                    justify-content: center;

                    .inner-marker {
                        width: 2px;
                        height: 100%;
                        background-color: var(--text-color-bright);
                    }

                    &.has-hovered .inner-marker {
                        background-color: var(--identifier-highlight-color)
                    }

                    &.disabled .inner-marker {
                        background-color: #525252;
                    }

                    &.active .inner-marker {
                        background-color: var(--keyword-color);
                    }
                }
            }

            .stage {
                overflow: hidden;
                flex: 1;

                fill: var(--text-color);

                display: flex;

                > p {
                    flex: 1;
                }

                .renderer {
                    // flex: 1;
                    // height: 100%;
                }

                .range-select {
                    padding: 5px 20px;
                }
            }

            .globals {
                width: 250px;
                height: 100%;

                border-left: 1px solid var(--text-color);
                cursor: default;

                .global {
                    padding: 10px;
                    min-height: 30px;
                    border-bottom: 1px solid var(--text-color);
                    color: var(--text-color-dim);

                    input {
                        width: 50px;
                    }

                    &.in-top {
                        color: var(--text-color-bright);
                    }

                    .color {
                        $rad: 10px;
                        position: relative;
                        width: 2*$rad + 20;
                        flex-shrink: 0;
                        align-items: center;
                        flex: initial;

                        .swatch {
                            position: relative;

                            width: 2*$rad;
                            height: 2*$rad;
                            border-radius: $rad;
                            overflow: hidden;
                        }

                        .picker {
                            position: absolute;
                            top: 55px;
                            left: -65px;
                            z-index: 1;
                        }
                    }

                    .params {
                        display: flex;
                        flex-direction: column;

                        input {
                            flex: 1;
                        }

                        span {
                            flex: 1;
                        }
                    }

                    &.hover .id-name {
                        color: var(--identifier-highlight-color);
                    }

                    .layer-controls {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .delete {
                            color: var(--delete-color);
                            @include button(var(--delete-color-highlight));
                        }
                    }
                }
            }
        }
    }


}
